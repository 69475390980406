<template>
  <div class="row mt-3 mb-5 selection-first">
    <div class="col-md-6 trs-pos mt-5 tr-font-title">
      <img src="@/assets/img/logo_transenix.svg" class="img-fluid py-5" alt="">
      <h2 class="pos1">{{ $t(`FirstPage.pos1`) }}</h2>
      <h2 class="pos2 tr-text-blue">{{ $t(`FirstPage.pos2`) }}</h2>
    </div>
    <div class="col-md-6 position-relative block-fi animate__animated animate__zoomIn">
      <img src="@/assets/img/f18.png" class="img-fluid app-sm-img my-5" alt="app img">
      <img src="@/assets/img/f20.png" class="app-bg-img" alt="app img">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpFirst',
}
</script>

<style scoped>
.trs-pos {
}

.pos1, .pos2{
  font-size: 2em;
}

.pos1 {
  color: #181717;
  font-style: normal;
  font-weight: 700;
}

.pos2 {
  font-style: normal;
  font-weight: 700;
}

.selection-first {
  /*background-size: cover;*/
  /*background: url("~@/assets/img/f20.png") no-repeat;*/
}
</style>
