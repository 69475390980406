<template>
  <div class="selection-contactUs mt-5 position-relative" id="s-contact">
    <img class="selection-contactUs-bg" src="@/assets/img/Frame131.svg" alt="">

    <div class="container py-4">
      <h3 class="selection-title mb-5">{{ $t(`Selection.title.contactUs`) }}</h3>
      <div class="row">
        <div class="col-lg-6 col-sm-12 cn-contact">
          <form class="row g-3 needs-validation" method="post" action="sm.php">
            <div class="col-md-6">
              <label for="inputName" class="form-label fw-bold">{{ $t(`ContactUs.form.name`) }}</label>
              <input type="text" class="form-control" id="inputName" name="inputName" required>
            </div>
            <div class="col-md-6">
              <label for="inputCompany" class="form-label fw-bold">{{ $t(`ContactUs.form.Company`) }}</label>
              <input type="text" class="form-control" id="inputCompany" name="inputCompany" required>
            </div>

            <div class="col-md-6">
              <label for="inputPhoneNumber" class="form-label fw-bold">{{ $t(`ContactUs.form.PhoneNumber`) }}</label>
              <input type="text" class="form-control" id="inputPhoneNumber" name="inputPhoneNumber" required>
            </div>
            <div class="col-md-6">
              <label for="inputEmail" class="form-label fw-bold">{{ $t(`ContactUs.form.Email`) }}</label>
              <input type="email" class="form-control" id="inputEmail" name="inputEmail" required>
            </div>

            <div class="col-md-12">
              <label for="inputMessage" class="form-label fw-bold">{{ $t(`ContactUs.form.Message`) }}</label>
              <textarea rows="4" class="form-control" id="inputMessage" name="inputMessage" required></textarea>
            </div>

            <div class="col-12">
              <button type="submit" class="btn btn-primary tr-bg-blue text-white w-100">{{ $t(`Btn.sendForm`) }}</button>
            </div>
          </form>
        </div>

        <div class="col-lg-6 col-sm-12">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TrpContactUs',
}
</script>

<style scoped>
.selection-contactUs {
  background: #F1F3F7;
}

.cn-contact{
  z-index: 20;
}
</style>
