import {createApp} from 'vue'
import App from './App.vue'
import i18n from './i18n'
import 'bootstrap/dist/css/bootstrap.css'
import "@/assets/css/main.css";
import VueAnimateOnScroll from 'vue3-animate-onscroll';
import VueSmoothScroll from 'vue3-smooth-scroll'

createApp(App).use(i18n).use(VueAnimateOnScroll).use(VueSmoothScroll).mount('#app')
import 'bootstrap/dist/js/bootstrap.js'
