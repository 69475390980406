<template>
  <div class="selection-FAQ mt-5" id="s-fac">
    <h3 class="selection-title tr-font-title mb-5">{{ $t(`Selection.title.FAQ`) }}</h3>
    <div class="row">
      <div class="col">
        <div class="accordion accordion-flush" id="accordionFlushFAQ1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading1">
              <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
                {{ $t(`FAQ.accordion.title1`) }}
              </button>
            </h2>
            <div id="flush-collapse1" class="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlushFAQ1">
              <div class="accordion-body">
                {{ $t(`FAQ.accordion.text1`) }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion accordion-flush" id="accordionFlushFAQ2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading2">
              <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
                {{ $t(`FAQ.accordion.title2`) }}
              </button>
            </h2>
            <div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlushFAQ2">
              <div class="accordion-body">
                {{ $t(`FAQ.accordion.text2`) }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion accordion-flush" id="accordionFlushFAQ3">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading3">
              <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
                {{ $t(`FAQ.accordion.title3`) }}
              </button>
            </h2>
            <div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlushFAQ3">
              <div class="accordion-body">
                {{ $t(`FAQ.accordion.text3`) }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion accordion-flush" id="accordionFlushFAQ4">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading4">
              <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
                {{ $t(`FAQ.accordion.title4`) }}
              </button>
            </h2>
            <div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlushFAQ4">
              <div class="accordion-body">
                {{ $t(`FAQ.accordion.text4`) }}
              </div>
            </div>
          </div>
        </div>

        <div class="accordion accordion-flush" id="accordionFlushFAQ5">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-heading5">
              <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
                {{ $t(`FAQ.accordion.title5`) }}
              </button>
            </h2>
            <div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlushFAQ5">
              <div class="accordion-body" style="white-space: pre-line">
                {{ $t(`FAQ.accordion.text5`) }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpFAQ',
}
</script>

<style scoped>
</style>
