<template>
  <div class="selection-saf mt-5 position-relative" id="s-security">
    <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f20.png" class="bg-saf" alt="app img">
    <h3 v-animate-onscroll="'animate__animated animate__slideInLeft'" class="selection-title tr-font-title mb-5">{{ $t(`Selection.title.SecurityAndFunctionality`) }}</h3>
    <div class="row">

      <div class="col-lg-4 mb-3">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'" class="saf-sp saf-sp-1 tr-bg-grey p-4 rounded-4">
          <img src="@/assets/img/charm_shield-tick.svg" alt="">
          <div class="fw-bold tr-font-title fs-5 mb-2 mt-3">{{ $t(`SecurityAndFunctionality.Security`) }}</div>
          <ul>
            <li>{{ $t(`SecurityAndFunctionality.leftList.text1`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.leftList.text2`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.leftList.text3`) }}</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 mb-3">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'" class="saf-sp saf-sp-2 tr-bg-grey p-4 rounded-4">
          <img src="@/assets/img/ic_baseline-pin.svg" alt="">
          <div class="fw-bold tr-font-title fs-5 mb-2 mt-3">{{ $t(`SecurityAndFunctionality.PINInput`) }}</div>
          <ul>
            <li>{{ $t(`SecurityAndFunctionality.centerList.text1`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.centerList.text2`) }}</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-4 mb-3">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'" class="saf-sp saf-sp-3 tr-bg-grey p-4 rounded-4">
          <img src="@/assets/img/material-symbols_refresh-rounded1.svg" alt="">
          <div class="fw-bold tr-font-title fs-5 mb-2 mt-3">{{ $t(`SecurityAndFunctionality.Functionality`) }}</div>
          <ul>
            <li>{{ $t(`SecurityAndFunctionality.rightList.text1`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.rightList.text2`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.rightList.text3`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.rightList.text4`) }}</li>
            <li>{{ $t(`SecurityAndFunctionality.rightList.text5`) }}</li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpSecurityAndFunctionality',
}
</script>

<style scoped>
.saf-sp-1 ul li{
  margin-bottom: 1rem;
}

.saf-sp-2 ul li{
  margin-bottom: 1.5rem;
}

</style>
