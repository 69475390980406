<template>
  <header class="site-header tr-bg-light">

    <nav class="navbar sticky-top navbar-expand-lg navbar-light tr-bg-light">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <a href="/files/FactSheetEN.pdf" class="me-3 d-block d-lg-none d-xl-none d-xxl-none">
          <img src="@/assets/img/mdi_external-link.svg" alt="" class="pb-1 me-1">White Paper
        </a>

        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a class="nav-link" href="#s-solution" v-smooth-scroll>{{ $t(`Menu.trSolution`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-whereUsed" v-smooth-scroll>{{ $t(`Menu.whereUsed`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-convenience" v-smooth-scroll>{{ $t(`Menu.Convenience`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-security" v-smooth-scroll>{{ $t(`Menu.Security`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-how-it-works" v-smooth-scroll>{{ $t(`Menu.howItWorks`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-fac" v-smooth-scroll>{{ $t(`Menu.fac`) }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#s-contact" v-smooth-scroll>{{ $t(`Menu.contactUs`) }}</a>
            </li>
          </ul>
          <div class="lang">
            <div class="row g-0">
              <div class="col-lg-12 col-xl-7 text-nowrap m-auto"><a
                  :href="'/files/Partners ' + $t(`Menu.link.partnersLangFile`) + '.pdf'"
                  class="me-3">
                <img src="@/assets/img/mdi_external-link.svg" alt="" class="me-1">
                <span>{{ $t(`Menu.link.partners`) }}</span>
              </a>

                <a :href="'/files/White paper ' + $t(`Menu.link.partnersLangFile`) + '.pdf'" class="me-5">
                  <img src="@/assets/img/mdi_external-link.svg" alt="" class="me-1">
                  <span class="">White Paper</span>
                </a></div>
              <div class="col-lg-12 col-xl-5">
                <div @click="switchLocale('uk')" v-bind:class="{ activeLang: (this.$i18n.locale === 'uk') }"
                     class="btn lang-btn">UK
                </div>
                <div @click="switchLocale('en')" v-bind:class="{ activeLang: (this.$i18n.locale === 'en') }"
                     class="btn lang-btn">EN
                </div>
                <div @click="switchLocale('es')" v-bind:class="{ activeLang: (this.$i18n.locale === 'es') }"
                     class="btn lang-btn">ES
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'TrpNavbar',
  computed: {
    // supportedLocales() {
    //   return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    // },
    // currentLocale() {
    //   return this.$i18n.locale;
    // },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;

      }
    }
  }
}
</script>

<style scoped>
.tr-bg-light {
  background: linear-gradient(90.04deg, #F1F3F7 0.03%, #DBDEE2 99.98%);
}

.activeLang {
  font-weight: bold;
}
</style>
