<template>
  <div class="selection-eou position-relative mt-5" id="s-convenience">
    <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f28.svg" class="bg-eou" alt="">
    <h3 v-animate-onscroll="'animate__animated animate__slideInLeft'" class="selection-title tr-font-title mb-5">
      {{ $t(`Selection.title.EaseOfUse`) }}</h3>
    <div class="row">
      <div class="col-md-6">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'" class="eou-sp tr-bg-grey p-4 rounded-4">
          <img src="@/assets/img/icon-park-solid_speed.svg" alt="">
          <div class="fw-bold fs-5 tr-font-title my-2">{{ $t(`EaseOfUse.SpeedFlexibility`) }}</div>
          <ul>
            <li>{{ $t(`EaseOfUse.leftList.text1`) }}</li>
            <li>{{ $t(`EaseOfUse.leftList.text2`) }}</li>
            <li>{{ $t(`EaseOfUse.leftList.text3`) }}</li>
            <li>{{ $t(`EaseOfUse.leftList.text4`) }}</li>
            <li>{{ $t(`EaseOfUse.leftList.text5`) }}</li>
          </ul>
        </div>
      </div>
      <div class="col-md-6">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'" class="eou-dp">
          <div class="eou-sp tr-bg-grey p-4 rounded-4">
            <img src="@/assets/img/material-symbols_refresh-rounded.svg" alt="">
            <div class="fw-bold fs-5 tr-font-title my-2">{{ $t(`EaseOfUse.Benefits`) }}</div>
            <ul>
              <li>{{ $t(`EaseOfUse.rightList.text1`) }}</li>
              <li>{{ $t(`EaseOfUse.rightList.text2`) }}</li>
              <li>{{ $t(`EaseOfUse.rightList.text3`) }}</li>
              <li>{{ $t(`EaseOfUse.rightList.text4`) }}</li>
              <li>{{ $t(`EaseOfUse.rightList.text5`) }}</li>
              <li>{{ $t(`EaseOfUse.rightList.text6`) }}</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpEaseOfUse',
}
</script>

<style scoped>

</style>
