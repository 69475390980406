export default {
  "Btn": {
    "sendForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
  },
  "ContactUs": {
    "form": {
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone "])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
    }
  },
  "EaseOfUse": {
    "Benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])},
    "SpeedFlexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed and flexibility"])},
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration via a few simple clicks"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application's user interface is intuitive"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage additional terminals and view transaction information from a mobile or web interface"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ease of network scaling"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional integration with external mobile apps from retailers, retail chains, and logistics companies"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile application does not require additional equipment"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support via chatbot in the application"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A completely mobile solution"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creates an alternative payment method"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mobile application is available 24/7 "])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add/remove terminals using the smartphone and without involving the bank"])}
    }
  },
  "FAQ": {
    "accordion": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is an application that allows merchants to accept payment for goods and services using a smartphone"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not currently. We will publish updates when this is available"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have questions about working with the Transenix application, please contact us using the chat from the \"Help\" section in the app "])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transenix accepts QR code payment, all types of contactless Visa and Mastercard cards, payments using Apple Pay, Google Pay, Garmin Pay, Swatch Pay, Samsung Pay, Mi Pay technologies"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Open the Transenix app\n- Transaction amount is specified\n- The customer holds their card or device close to merchant smartphone\n- Encrypted data is transferred to the bank\n- You receive a notification about the transaction success"])},
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the Transenix App? "])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does the Transenix App work on iOS?"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is there customer support during set-up and usage?"])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What payment methods and cards can Transenix accept?"])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I accept payment using the Transenix app? "])}
    }
  },
  "FOOTER": {
    "nameCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSPLAT LLC"])}
  },
  "FirstPage": {
    "pos1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS - TERMINAL IN A SMARTPHONE."])},
    "pos2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCEPTANCE OF CONTACTLESS CARDS AND DIGITAL GADGETS"])}
  },
  "HowItWorks": {
    "blockText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The merchant inputs the transaction amount"])},
    "blockText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client tap a card or payment gadget to the merchant's smartphone "])},
    "blockText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acquiring Bank processes the transaction and reports the (successful) result "])},
    "blockText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the request of the client, the merchant sends an electronic reciept by e-mail or messenger"])}
  },
  "Menu": {
    "Convenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convenience"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "fac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "link": {
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" For Partners"])},
      "partnersLangFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])}
    },
    "trSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transenix Solution"])},
    "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where it can be used"])}
  },
  "SecurityAndFunctionality": {
    "Functionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functionality"])},
    "PINInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PIN-entry support"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "centerList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meets the requirements of payment systems and standards: SPoC (Software-based Pin-entry on COTS), PCI-DSS "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securely accept payments that exceed CVM limits with software-based PIN authentication without the need for a separate device to enter a PIN"])}
    },
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Evaluation Security Certification in accordance with the requests of payment systems"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensitive card data is not stored on the mobile device"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialized secure environment for security key storage"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote registration of the merchant "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementation of Purchase, Reversal, Refund transactions"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collect statistics on completed transactions"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display history of transactions"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing an e-receipt by SMS, E-MAIL, QR "])}
    }
  },
  "Selection": {
    "title": {
      "EaseOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convenience of use"])},
      "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "HowItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
      "SecurityAndFunctionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security and functionality"])},
      "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
      "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can this be used?"])}
    }
  },
  "Solution": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payments using contactless cards"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept payments using digital wallets and wearables"])}
  },
  "WhereUsed": {
    "btIcon": {
      "AndOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["And<br>more"])},
      "CafeAndRestaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cafes,<br>restaurants and bars"])},
      "CouriersDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couriers"])},
      "Logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistics"])},
      "SalonsBeauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauty<br>Salons"])},
      "SportsHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gym"])},
      "Taxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxis"])},
      "TouristAgencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel<br>agencies "])},
      "autoRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car<br>repairs"])},
      "tradingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading<br> points"])}
    },
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSENIX is an innovative system for accepting digital payments, focused largely on small and medium-sized businesses: sole traders and legal entities working in all areas of trade and service provision."])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}