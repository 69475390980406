<template>
  <trp-navbar></trp-navbar>
  <div class="container mt-5">
    <trp-first></trp-first>
    <trp-solution></trp-solution>
    <trp-where-used></trp-where-used>
    <trp-ease-of-use></trp-ease-of-use>
    <trp-security-and-functionality></trp-security-and-functionality>
    <trp-how-it-works></trp-how-it-works>
    <trp-f-a-q></trp-f-a-q>
  </div>
  <trp-contact-us></trp-contact-us>
  <trp-footer></trp-footer>

</template>

<script>
import TrpNavbar from '@/components/NavBar'
import TrpFirst from "@/components/First";
import TrpSolution from "@/components/Solution";
import TrpWhereUsed from "@/components/WhereUsed";
import TrpEaseOfUse from "@/components/EaseOfUse";
import TrpSecurityAndFunctionality from "@/components/SecurityAndFunctionality";
import TrpHowItWorks from "@/components/HowItWorks";
import TrpFAQ from "@/components/FAQ";
import TrpContactUs from "@/components/Contact";
import TrpFooter from "@/components/Footer";

export default {
  name: 'App',
  components: {
    TrpFooter,
    TrpContactUs,
    TrpFAQ,
    TrpHowItWorks,
    TrpSecurityAndFunctionality,
    TrpEaseOfUse,
    TrpWhereUsed,
    TrpSolution,
    TrpFirst,
    TrpNavbar
  }
}
</script>

<style>
#app {
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
