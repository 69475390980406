<template>
  <div class="selection-whereused position-relative mt-5" id="s-whereUsed">
    <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f20.png" class="bg-whereUsed" alt="">
    <h3 v-animate-onscroll="'animate__animated animate__slideInLeft'" class="selection-title tr-font-title mb-5">{{ $t(`Selection.title.whereUsed`) }}</h3>
    <div v-animate-onscroll="'animate__animated animate__fadeIn'" class="row">
      <div class="col">
        <div class="wr-text1 fw-semibold tr-bg-grey p-3 rounded-4">
          {{ $t(`WhereUsed.text1`) }}
        </div>
      </div>
    </div>

    <div v-animate-onscroll="'animate__animated animate__fadeIn'" class="wr-text2 tr-bg-grey p-5 rounded-4 mt-5">
      <div class="row">
        <div class="col text-center">
          <img src="@/assets/img/where-used/entypo_shop.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.tradingPoints`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/material-symbols_local-cafe-rounded.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.CafeAndRestaurants`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/fluent_box-16-filled.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.CouriersDelivery`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/mingcute_truck-fill.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.Logistics`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/mdi_car-repair.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.autoRepair`)"></div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <img src="@/assets/img/where-used/map_gym.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.SportsHalls`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/fa-solid_taxi.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.Taxi`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/icon-park-solid_vacation.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.TouristAgencies`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/map_beauty-salon.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.SalonsBeauty`)"></div>
        </div>
        <div class="col text-center">
          <img src="@/assets/img/where-used/ex.svg" alt="">
          <div v-html="$t(`WhereUsed.btIcon.AndOthers`)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpWhereUsed',
}
</script>

<style scoped>
.wr-text1 {
font-size: 1.2em;
}
</style>
