export default {
  "Btn": {
    "sendForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])}
  },
  "ContactUs": {
    "form": {
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компанія"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше повідомлення"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Імʼя"])}
    }
  },
  "EaseOfUse": {
    "Benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переваги"])},
    "SpeedFlexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швидкість і гнучкість"])},
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація в застосунку виконується в декілька кліків в режимі online"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інтуїтивно зрозумілий інтерфейс додатку "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управління додатковими терміналами і перегляд інформаціії за транзакціями можливі з мобільного і з web інтерфейсу"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легкість масштабування мережі"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливість інтеграції з зовнішніми мобільними зостосунками рітейлерів, торгівельних мереж та логістичних компаній"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобільний застосунок не потребує додаткового обладнання"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка через чат-бот у застосунку"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місце обслуговування та оплати не привʼязано до стаціонарного приміщення"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створює альтернативний спосіб оплати"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мобільний застосунок завжди доступний для роботи 24/7"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можливість додавати/видаляти термінали на смартфоні без звернення до банку"])}
    }
  },
  "FAQ": {
    "accordion": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це застосунок, який дозволяє торговцям приймати оплату за товари та послуги за допомогою смартфона"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наразі ні. Ми повідомимо коли ця можливість буде доступна "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо у вас виникли запитання щодо роботи з додатком Transenix – звертайтеся в чат в додатку в розділі \"Допомога\" "])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transenix приймає оплату по QR, всі види безконтактних карток Visa та Mastercard, можлива оплата за допомогою технологій Apple Pay, Google Pay, Garmin Pay, Swatch Pay, Samsung Pay, Mi Pay"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Відкрийте застосунок Transenix\n- Вкажіть суму\n- Покупець прикладає картку або гаджет до смартфона з Transenix\n- Зашифровані дані передаються в банк\n- Ви отримуєте повідомлення про успішність транзакції"])},
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що таке Transenix App?"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи працює застосунок Transenix на iOS? "])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чи є підтримка під час підключення та використання?"])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Які платіжні методи та картки може  приймати Transenix? "])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як прийняти оплату за допомогою застосунку Transenix? "])}
    }
  },
  "FOOTER": {
    "nameCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLC TRANSPLAT"])}
  },
  "FirstPage": {
    "pos1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS - ТЕРМІНАЛ В СМАРТФОНІ."])},
    "pos2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБСЛУГОВУВАННЯ БЕЗКОНТАКТНИХ КАРТ ТА ЦИФРОВИХ ГАДЖЕТІВ"])}
  },
  "HowItWorks": {
    "blockText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець вказує суму в застосунку"])},
    "blockText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнт прикладає картку або гаджет для оплати до смартфону торговця"])},
    "blockText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакцію обробляє банк еквайр і повідомляє про успішний результат"])},
    "blockText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавець за бажанням клієнта відправляє електронний чек на пошту або в месенджер "])}
  },
  "Menu": {
    "Convenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зручність"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'язатись з нами"])},
    "fac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як це працює"])},
    "link": {
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнерам"])},
      "partnersLangFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])}
    },
    "trSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рішення Transenix"])},
    "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Де використовується"])}
  },
  "SecurityAndFunctionality": {
    "Functionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функціональність"])},
    "PINInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка вводу PIN "])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека"])},
    "centerList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відповідає вимогам платіжних систем і стандартів: SPoC (Software-base Pin-entry on COTS), PI-DSS"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдяки програмній автентифікації PIN-коду безпечно приймайте платежі, що перевищують ліміти CVM, не потребуючи окремого пристрою для введення PIN-коду"])}
    },
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертифікація з безпеки Security Evaluation у відповідності з запитами платіжних систем"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чутливі дані карток не зберігаються на мобільному пристрої"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для зберігання ключів безпеки використовується спеціалізоване безпечне середовище"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка віддаленої реєстраціі торговця"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здійснення транзакцій Purchase, Reversal ,Refund"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка збору аналітики щодо виконаних транзакцій"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відображення історії транзакцій"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надання електронного чеку за допомогою SMS, E-MAIL, QR"])}
    }
  },
  "Selection": {
    "title": {
      "EaseOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зручність використання"])},
      "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "HowItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Як це працює"])},
      "SecurityAndFunctionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безпека та функціональність"])},
      "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'яжіться з нами"])},
      "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Де може використовуватись технологія"])}
    }
  },
  "Solution": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийом платежів за допомогою безконтактних карток"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийом платежів за допомогою цифрових гаманців і носимих пристроїв"])}
  },
  "WhereUsed": {
    "btIcon": {
      "AndOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Та<br>Інші"])},
      "CafeAndRestaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кафе<br> та ресторани"])},
      "CouriersDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кур'єрська<br>доставка"])},
      "Logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логістика"])},
      "SalonsBeauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Салони<br>краси"])},
      "SportsHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спортивні<br>зали"])},
      "Taxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таксі"])},
      "TouristAgencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туристичні<br>агентства"])},
      "autoRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторемонт"])},
      "tradingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торгові<br> точки"])}
    },
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSENIX - це іноваційна система для прийому платежів в торгівельній мережі. Рішення може використовуватись представниками малого та середнього бізнесу - ФОПами та юридичними особами, що працюють у сферах торгівлі та надання послуг, які можуть не мати постійноії локації торгівельної точки"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])}
}