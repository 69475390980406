<template>
  <div class="selection-solution position-relative" id="s-solution">
    <img src="@/assets/img/f28.svg" class="bg-solution" alt="">
    <h3 v-animate-onscroll="'animate__animated animate__slideInLeft'" class="selection-title selection-solution-title tr-font-title">{{ $t(`Menu.trSolution`) }}</h3>
    <div class="row">
      <div class="col text-center position-relative mb-5 overflow-hidden">
        <div class="m-auto">
          <div class="sl-w500 w-100 position-relative">
            <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f12.png" class="sl-f12 img-fluid" alt="">
            <div v-animate-onscroll="'animate__animated animate__fadeIn'" class="tr-bg-grey p-3 rounded-4 sl-f12-text">{{ $t(`Solution.text1`) }}</div>
            <img v-animate-onscroll="'animate__animated animate__slideInRight'" src="@/assets/img/f11.png" class="sl-f11 img-fluid" alt="">
            <img src="@/assets/img/f7.png" class="sl-f7 img-fluid my-5" alt="">
            <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f24.png" class="sl-f24 img-fluid" alt="">
            <div v-animate-onscroll="'animate__animated animate__fadeIn'" class="tr-bg-grey p-3 rounded-4 sl-f24-text">{{ $t(`Solution.text2`) }}</div>
            <img v-animate-onscroll="'animate__animated animate__slideInLeft'" src="@/assets/img/f10.png" class="sl-f10 img-fluid" alt="">
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: 'TrpSolution',
  components: {},
  methods: {
    test() {
      console.log("test")
    }
  }
}
</script>

<style scoped>
/*.selection-solution{*/
/*  background: url("~@/assets/img/f25.svg") no-repeat;*/
/*}*/

</style>