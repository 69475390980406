<template>
  <div class="selection-HowItWorks position-relative mt-5" id="s-how-it-works">
    <img v-animate-onscroll="'animate__animated animate__zoomIn'" src="@/assets/img/f28.svg" class="bg-how-it-works" alt="">
    <h3 v-animate-onscroll="'animate__animated animate__slideInLeft'" class="selection-title tr-font-title mb-5">{{ $t(`Selection.title.HowItWorks`) }}</h3>
    <div class="row">
      <div v-animate-onscroll="'animate__animated animate__backInRight'" class="col-lg-3 col-md-6 col-sm-12 mb-3 text-center hiw-bl1">
        <div class="hiw-tx tr-bg-grey p-4 rounded-4 mb-2">
          {{ $t(`HowItWorks.blockText1`) }}
        </div>
        <img src="@/assets/img/Pixel7Front.png" alt="">
      </div>

      <div v-animate-onscroll="'animate__animated animate__backInRight'" class="col-lg-3 col-md-6 col-sm-12 mb-3 text-center hiw-bl2">
        <div class="hiw-tx tr-bg-grey p-4 rounded-4 mb-2">
          {{ $t(`HowItWorks.blockText2`) }}
        </div>
        <img src="@/assets/img/Pixel7Front2.png" alt="">
      </div>

      <div v-animate-onscroll="'animate__animated animate__backInRight'" class="col-lg-3 col-md-6 col-sm-12 mb-3 text-center hiw-bl3">
        <div class="hiw-tx tr-bg-grey p-4 rounded-4 mb-2">
          {{ $t(`HowItWorks.blockText3`) }}
        </div>
        <img src="@/assets/img/Pixel7Front3.png" alt="">
      </div>

      <div v-animate-onscroll="'animate__animated animate__backInRight'" class="col-lg-3 col-md-6 col-sm-12 mb-3 text-center hiw-bl4">
        <div class="hiw-tx tr-bg-grey p-4 rounded-4 mb-2">
          {{ $t(`HowItWorks.blockText4`) }}
        </div>
        <img src="@/assets/img/Pixel7Front4.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrpHowItWorks',
}
</script>

<style scoped>
.hiw-tx{
  height: 120px;
}
.hiw-bl1{
  animation-delay: 0.3s;
}
.hiw-bl2{
  animation-delay: 0.5s;
}
.hiw-bl3{
  animation-delay: 0.7s;
}
.hiw-bl4{
  animation-delay: 0.9s;
}
</style>
