export default {
  "Btn": {
    "sendForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
  },
  "ContactUs": {
    "form": {
      "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su mensaje"])},
      "PhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])}
    }
  },
  "EaseOfUse": {
    "Benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficios"])},
    "SpeedFlexibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad y flexibilidad"])},
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El registro en la aplicación se realiza en unos pocos clics en línea"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interfaz de aplicación intuitivo y fácil de entender"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gestión de terminales adicionales y la visualización de la información de transacciones es posible desde el móvil y la interfaz web"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilidad para escalar la red"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posibilidad de integración con aplicaciones móviles externas de minoristas, cadenas comerciales y empresas logísticas"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación móvil no requiere hardware adicional"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte a través de chatbot en la aplicación"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lugar de servicio y pago no está vinculado a un edificio estacionario"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una forma alternativa de pago"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación móvil siempre está disponible para trabajar 24/7"])},
      "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posibilidad de agregar/eliminar terminales en el smartphone sin recurrir al banco"])}
    }
  },
  "FAQ": {
    "accordion": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es una aplicación que permite a los comerciantes aceptar pagos por productos y servicios a través de un smartphone"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ahora no. Le informaremos cuando esta función esté disponible"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tiene preguntas sobre cómo trabajar con la aplicación Transenix, comuníquese con el chat en la aplicación en la sección \"Ayuda\""])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transenix acepta pagos por QR, todos los tipos de tarjetas sin contacto Visa y Mastercard, y es posible pagar con tecnologías como Apple Pay, Google Pay, Garmin Pay, Swatch Pay, Samsung Pay, Mi Pay"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Abra la aplicación Transenix\n- Indique la cantidad\n- El cliente aplica su tarjeta o dispositivo al smartphone con Transenix\n- Los datos cifrados se envían al banco\n- Recibe una notificación sobre el éxito de la transacción"])},
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué es la aplicación Transenix?"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Funciona la aplicación Transenix en iOS?"])},
      "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Hay soporte durante la conexión y uso?"])},
      "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué métodos de pago y tarjetas puede aceptar Transenix?"])},
      "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo aceptar un pago con la aplicación Transenix?"])}
    }
  },
  "FOOTER": {
    "nameCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLC TRANSPLAT"])}
  },
  "FirstPage": {
    "pos1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POS - TERMINAL EN EL SMARTPHONE."])},
    "pos2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICIO DE TARJETAS SIN CONTACTO Y DISPOSITIVOS DIGITALES"])}
  },
  "HowItWorks": {
    "blockText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El vendedor indica la cantidad en la aplicación"])},
    "blockText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cliente aplica su tarjeta o dispositivo para el pago al smartphone del comerciante"])},
    "blockText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El banco adquirente procesa la transacción y notifica sobre el resultado exitoso"])},
    "blockText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el cliente lo desea, el vendedor envía un recibo electrónico por correo o mensajero"])}
  },
  "Menu": {
    "Convenience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comodidad"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
    "fac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo funciona"])},
    "link": {
      "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socios"])},
      "partnersLangFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])}
    },
    "trSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solución Transenix"])},
    "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde se utiliza"])}
  },
  "SecurityAndFunctionality": {
    "Functionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funcionalidad"])},
    "PINInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte para ingreso de PIN"])},
    "Security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
    "centerList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumple con los requisitos de los sistemas de pago y estándares: SPoC (Entrada de PIN basada en software en COTS), PI-DSS"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias a la autenticación de software PIN, acepte pagos que excedan los límites de CVM sin necesitar un dispositivo separado para ingresar el PIN"])}
    },
    "leftList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificación de Evaluación de Seguridad de acuerdo con los requerimientos de los sistemas de pago"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos sensibles de la tarjeta no se almacenan en el dispositivo móvil"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para almacenar claves de seguridad se utiliza un entorno seguro especializado"])}
    },
    "rightList": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte para registro remoto del comerciante"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realización de transacciones de Compra, Reversión, Reembolso"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte para recopilación de análisis de transacciones realizadas"])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar historial de transacciones"])},
      "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporcionar un recibo electrónico a través de SMS, E-MAIL, QR"])}
    }
  },
  "Selection": {
    "title": {
      "EaseOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilidad de uso"])},
      "FAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "HowItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo funciona"])},
      "SecurityAndFunctionality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad y funcionalidad"])},
      "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contáctenos"])},
      "whereUsed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde se puede usar la tecnología"])}
    }
  },
  "Solution": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación de pagos mediante tarjetas sin contacto"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación de pagos mediante billeteras digitales y dispositivos portátiles"])}
  },
  "WhereUsed": {
    "btIcon": {
      "AndOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y<br>Otros"])},
      "CafeAndRestaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cafés<br>y restaurantes"])},
      "CouriersDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega<br>de mensajería"])},
      "Logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logística"])},
      "SalonsBeauty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salones<br>de belleza"])},
      "SportsHalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gimnasios"])},
      "Taxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxi"])},
      "TouristAgencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencias<br>de turismo"])},
      "autoRepair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talleres de auto"])},
      "tradingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntos<br>de venta"])}
    },
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRANSENIX es un sistema innovador para aceptar pagos en la red comercial. La solución puede ser utilizada por representantes de pequeñas y medianas empresas - autónomos y personas jurídicas que trabajan en los sectores de comercio y servicios y que pueden no tener una ubicación permanente de punto de venta"])}
  },
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hola i18n!"])}
}