<template>
  <div class="selection-Footer py-5">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <div class="row">
            <div class="col">
              <img src="@/assets/img/logo_transplat_white.svg" width="152" alt="Logo">
            </div>
            <div class="col">
              <img src="@/assets/img/fa6-solid_building.png" alt="">
              <span class="text-white ms-2">{{ $t(`FOOTER.nameCompany`) }}</span><br>
              <span class="text-white pl-address">UAB Laisves pr. 60,</span><br>
              <span class="text-white pl-address">Vilnius</span><br>

              <img src="@/assets/img/ic_round-mail.png" alt="">
              <span class="text-white ms-2"><a href="mailto: info@transplat.eu"></a>info@transplat.eu</span>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-lg-3 col-xl-3 col-xxl-2">
          <div class="row text-center">
            <div class="col-sm-6 col-6">
              <a href="https://certs.advantio.com/client/1QwV3GjPEe0lNpZ" target="_blank">
                <img src="@/assets/img/pcidss-cert.svg" alt="PCI-DSS" class="img-fluid">
              </a>
            </div>
            <div class="col-sm-6 col-6">
              <img src="@/assets/img/pcidss-gdpr.svg" alt="GDPR" class="img-fluid h-50 mt-2">
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TrpFooter',
}
</script>

<style scoped>
.selection-Footer {
  background: #181717;
}

.pl-address {
  padding-left: 2rem;
}
</style>
